// @flow

import Token from './model/token.model';
import AuthConf from './model/auth-conf.model';
import { getStoredData, getApiClient } from './utils';
import { InlineResponse400 } from '../api/mautic/src';

// we don't want to accidentally make multiple auth requests to the API
const postDataRequests = new Map();

/**
 * Where Mautic returns the user to after a success oauth2 process
 */
const successUri = '/onboarding/auth-success.html';

/**
 * Get a token that is unique for each board
 * @param {Trello} t Global Trello Object
 */
function getTokenName(t: any) {
  const context = t.getContext();
  return `token${context.board}`;
}

/**
 * Get Authorization config values
 * @param {Trello} t Global Trello Object
 */
export function getConfiguration(t: any): Promise<AuthConf> {
  return Promise.all([
    getStoredData(t, 'mauticUrl'),
    getStoredData(t, 'publicKey'),
    getStoredData(t, 'secretKey'),
  ]).then(([mauticUrl, publicKey, secretKey]) => {
    // if (!mauticUrl || !publicKey || !secretKey) {
    //   return new AuthConf();
    // }
    return new AuthConf({ mauticUrl, publicKey, secretKey });
  });
}

export function updateConfiguration(trl: any, name: string, value: any): Promise<AuthConf> {
  let scope = 'shared';
  if (name === 'secretKey') {
    scope = 'private';
  }

  if (!value && value !== false) {
    // console.debug('setting removed');
    return trl.remove('board', scope, name).then(() => getConfiguration(trl));
  }

  if (typeof value === 'string') {
    value = value.trim();
  }
  if (name === 'mauticUrl' && value.charAt(value.length - 1) === '/') {
    value = value.substring(0, value.length - 1);
  }

  return trl.set('board', scope, name, value).then(() => getConfiguration(trl));
}

/**
 * Handle the posting to an endpoint. Kill multiple requests to the same url.
 * Returns the response as JSON
 *
 * @param {object} trello main Trello object
 * @param {object} data data to POST
 */
function fetchTokenData(trello, data): Promise<Token> {
  function handleApiError(response: Response) {
    const inline400: InlineResponse400 = InlineResponse400.constructFromObject(response.body);

    if (inline400 instanceof InlineResponse400 && inline400.errors[0]) {
      throw new Error(inline400.errors[0].message);
    } else {
      throw new Error('Could not get Token from API');
    }
  }
  /**
   * Handle the Response from Mautic and return a new Token
   * @param {Object} data Mautic Response Data
   */
  function fetchTokenDataTokenSuccess(responseData): Token {
    if (
      !responseData ||
      !responseData.data ||
      !responseData.data.access_token ||
      !responseData.data.refresh_token
    ) {
      // console.debug('fetch data', responseData);
      throw new Error('Wrong oauth token data');
    }

    return new Token({
      accessToken: responseData.data.access_token,
      refreshToken: responseData.data.refresh_token,
      expiresIn: responseData.data.expires_in,
    });
  }

  const path = '/oauth/v2/token';
  if (postDataRequests.has(path)) {
    // we already have a request in progress for that url, let's reuse that
    // console.debug('use existing pending request');
    // $FlowFixMe
    return postDataRequests.get(path);
  }

  const request = getApiClient(trello).then((api) => {
    if (!api) {
      throw new Error('could not get API');
    }
    const pathParams = {};
    const queryParams = {};
    // const headerParams = {};
    const headerParams = { 'X-Requested-With': 'XMLHttpRequest' };
    const formParams = {};
    const authNames = [];
    const contentTypes = [];
    const accepts = ['application/json'];
    const returnType = Token;
    return api
      .callApi(
        path,
        'POST',
        pathParams,
        queryParams,
        headerParams,
        formParams,
        data,
        authNames,
        contentTypes,
        accepts,
        returnType
      )
      .then((responseData) => {
        postDataRequests.delete(path);
        return fetchTokenDataTokenSuccess(responseData);
      })
      .catch(handleApiError);
  });
  // store the outstanding request so it can be reused
  // console.debug('store a new post data request', request);
  postDataRequests.set(path, request);
  return request;
}

/**
 * Request a new token with a code
 */
export default function requestToken(
  trello: any,
  publicKey: string,
  secretKey: string,
  code: string
): Promise<Token> {
  const params = {
    client_id: publicKey,
    client_secret: secretKey,
    grant_type: 'authorization_code',
    redirect_uri: `https://${window.location.host}${successUri}`,
    code,
  };
  return fetchTokenData(trello, params);
}

/**
 * Refresh an existing token (works within 14 days)
 * @param {object} trello main Trello object
 * @param {string} publicKey Mautic Public Key
 * @param {string} secretKey Mautic Secret Key
 * @param {string} refreshToken Token used to get a new actual Token
 * @link https://developer.mautic.org/#oauth-2
 */
export function requestFreshToken(
  trello: any,
  publicKey: string,
  secretKey: string,
  refreshToken: string
): Promise<Token> {
  const params = {
    client_id: publicKey,
    client_secret: secretKey,
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    redirect_uri: `https://${window.location.host}${successUri}`,
  };
  return fetchTokenData(trello, params);
}
/**
 * Handles the creation and saving of a new Token (refresh)
 * @param {Trello} t Global Trello Object
 * @param {Token} token existing (expired) Token
 */
export function handleFreshToken(t: any, token: Token): Promise<Token> {
  const context = t.getContext();

  return getConfiguration(t)
    .then((conf) => {
      if (!conf.isValid() || !token.refreshToken) {
        // console.debug('no valid conf or token data');
        return new Token();
      }
      return requestFreshToken(t, conf.publicKey, conf.secretKey, token.refreshToken);
    })
    .then((newToken: Token) => {
      t.storeSecret(`token${context.board}`, JSON.stringify(newToken));
      // console.debug('Stored a fresh token', token);
      return newToken;
    });
}
/**
 * Get an existing oAuth2 Token from its secret stash
 * @param {Trello} t the Trello object
 */
export function getToken(t: any): Promise<Token> {
  const tokenName = getTokenName(t);

  return t
    .loadSecret(tokenName)
    .then((tokenData) => {
      try {
        const token = new Token(JSON.parse(tokenData));

        if (!token.isValid() && token.refreshToken) {
          // console.debug('token not valid');
          return handleFreshToken(t, token);
        }
        // return a valid token
        return token;
      } catch (e) {
        console.warn('no valid json for token');
        return new Token();
      }
    })
    .catch((error) => {
      if (error instanceof DOMException && error.name === 'SecurityError') {
        t.alert({
          message: t.localizeKey('browserSecurityError'),
          duration: 30, // max 30
          display: 'warning',
        });
      } else {
        console.warn(error);
        t.alert({
          message: t.localizeKey('couldNotGetToken'),
          duration: 30, // max 30
          display: 'warning',
        });
      }
      return new Token();
    });
}

/**
 * Clear the Login Token from the board
 * @param {Trello} t Global Trello Object
 */
export function clearToken(t: any) {
  return t.clearSecret(getTokenName(t));
}

/**
 * Generate a secure state
 */
function getNewState(): string {
  return Math.random().toString(36).slice(-8);
}

/**
 *
 * @param {string} base base url e.g. https://domain.chart
 * @param {Object} params Additional params
 */
function getUrl(base, params): string {
  let url = `${base}?`;
  const paramsArray = Object.keys(params);
  paramsArray.forEach((param) => {
    url += `${param}=${encodeURIComponent(params[param])}&`;
  });
  return url;
}

/**
 * Generate the Mautic oAuth2.0 Url and save the state
 */
export function getOauthUrl(t: any, conf: AuthConf): string {
  if (!conf) {
    return '';
  }

  const state = getNewState();
  t.set('member', 'private', 'state', state);

  const authParams = {
    client_id: conf.publicKey,
    grant_type: 'authorization_code',
    redirect_uri: `https://${window.location.host}${successUri}`,
    response_type: 'code',
    state,
  };
  return getUrl(`${conf.mauticUrl}/oauth/v2/authorize`, authParams);
}

/**
 * Check if the user is authorized, checks only on Settings
 * @param {Trello} t Global Trello Object
 */
export function getAuthorizationStatus(t: any) {
  return getToken(t).then((token: Token) => {
    if (!token.isValid()) {
      if (token.refreshToken) {
        return handleFreshToken(t, token);
      }

      return Promise.resolve({ authorized: false });
    }
    return Promise.resolve({ authorized: token.isValid() });
  });
}
/**
 * Remove personal settings, including the API secret key
 * remove-data capability, add on-disable
 */
export function logoutAndClearUser(t: any) {
  const context = t.getContext();
  const tokenName = `token${context.board}`;
  try {
    t.remove('board', 'private', 'secretKey');
    // legacy
    t.remove('board', 'shared', 'secretKey');
    // t.remove('member', 'private', 'myKey');
    return t.clearSecret(tokenName).then(() => {
      t.alert({
        message: t.localizeKey('removedLoginData'),
        display: 'info',
      });
    });
  } catch (error) {
    console.error(error);
    return error;
  }
}
/**
 * Remove the API Settings (public key and url)
 * @param {Trello} t Global Trello Object
 */
export function clearApiData(t: any) {
  t.remove('board', 'private', 'secretKey');
  // for legacy reasons, leave secretKey key here
  return t.remove('board', 'shared', ['publicKey', 'secretKey', 'mauticUrl']).then(() => {
    return t.alert({
      message: t.localizeKey('removedApiData'),
      display: 'info',
    });
  });
}
