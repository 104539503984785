// @flow

class AuthConf {
  mauticUrl: string;

  publicKey: string;

  secretKey: string;

  constructor(data: Object) {
    if (!data) {
      return null;
    }
    this.mauticUrl = data.mauticUrl || null;
    this.publicKey = data.publicKey || null;
    this.secretKey = data.secretKey || null;
  }

  isValid() {
    if (!this.mauticUrl || !this.publicKey || !this.secretKey) {
      return false;
    }
    return true;
  }

  getValue(key: string): string {
    if (!key) {
      throw new Error('no key to get');
    }
    // $FlowFixMe
    return this[key] || '';
  }
}
export default AuthConf;
